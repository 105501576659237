import { cardPageController, actionCard } from 'magner';
import { baseT } from 'configs/translation/base';
import { ElLoading, ElMessage, ElMessageBox } from 'magner/element-plus';
import type { Action as ElAction } from 'magner/element-plus';
import type { Entity } from 'features/catalog-import/categories/types';
import { get, update } from './requests';
import { t } from './ru';
import PricesTable from './table.vue';
import { request } from '~/utils/request';

export const cardConfig = cardPageController<Record<number, number>>({
  header: {
    title: t('pages.card_title'),
  },

  getRequest: get,
  createRequest: update,
  updateRequest: update,

  form: {
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        props: {
          text: 'Импорт из файла на сервере',
          type: 'danger',
        },
        action: actionCard(({ data }) => {
          // ToDo: refactor? Move all this logic to outer function. make correct request function.
          ElMessageBox.confirm(
            'Вы уверены, что хотите импортировать данные из файла на сервере? Все цены, минимальные цены и стоимости опций будут перезаписаны!',
            'Подтверждение',
            {
              confirmButtonText: 'Запустить импорт',
              cancelButtonText: 'Отменить',
              type: 'warning',
            },
          )
            .then(async () => {
              const loading = ElLoading.service({
                lock: true,
                text: 'Выполняется импорт',
                background: 'rgba(0, 0, 0, 0.7)',
              });
              request.api.post<{log: string}>('/api/admin/prices/import', {})
                .then((resp) => {
                  if (resp.error) {
                    ElMessageBox.alert('Не удаётся отобразить журнал импорта.', 'Ошибка обработки ответа сервера', {
                      confirmButtonText: 'Перезагрузить',
                      callback: (action: ElAction) => {
                        window.location.reload();
                      },
                    });
                  } else {
                    ElMessageBox.alert(`<div class="import-log"><pre>${resp.data.data.log}</pre></div>`, 'Журнал импорта', {
                      confirmButtonText: 'Перезагрузить',
                      dangerouslyUseHTMLString: true,
                      callback: (action: ElAction) => {
                        window.location.reload();
                      },
                    });
                  }
                })
                .catch((e) => {
                  ElMessage({
                    type: 'error',
                    message: 'Ошибка выполнения',
                  });
                })
                .finally(() => {
                  loading.close();
                });
            });
        }),
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          title: '',
          props: { span: 24 },
          fields: [
            {
              type: 'custom',
              name: 'items',
              dataType: 'object',
              component: () => PricesTable,
              props: {},
            },
          ],
        },
      ],
    },
  },
});
