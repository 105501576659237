import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';

const BASE = '/api/admin/import-goods/product';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';
  const supplier = data.filters.supplierId ? `&filters[1][id]=supplierId&filters[1][value]=${data.filters.supplierId}` : '';
  const category = data.filters.categoryId ? `&filters[2][id]=supplierCategoryExternalId&filters[2][value]=${data.filters.categoryId}` : '';
  const unmappedOnly = data.filters.unmappedOnly ? '&filters[3][id]=unmappedOnly&filters[3][value]=1' : '';

  const result = await getListResponse<Entity>(`${BASE}/list?${pagination}${search}${supplier}${category}${unmappedOnly}`, activePage, data.filters.active);
  
  result.data.rows.forEach((item) => {
    item.treeId = `${item.id}_${Math.floor(Math.random() * 1000)}`;
  });

  return result;
});

// @ts-ignore
export const setParentCategory = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.post<{ data: { items: Entity[] } }>(`${BASE}/map`, { ...data });

  if (res.data) {
    return { data: res.data };
  }

  return { error: parseError(res.error) };
});

// @ts-ignore
export const removeParentCategory = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.post<{ data: { success: true } }>(`${BASE}/unmap`, { ...data });

  if (res.data) {
    return { data: res.data };
  }

  return { error: parseError(res.error) };
});
